import request from "@/utils/request";

export function updateUID(data) {
  return request({
    method: "post",
    url: "absen/update_uid",
    data: data
  });
}

export function getInfoUID(data) {
  return request({
    method: "post",
    url: "absen/uid",
    data: data
  });
}
