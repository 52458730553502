<template>
  <v-row justify="center">
    <v-dialog
      transition="scale-transition"
      persistent
      v-model="model"
      max-width="500px"
    >
      <v-card outlined>
        <v-card-title class="title">{{
          $t("personalization.input_card_number")
        }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="7">
              <div>{{ $t("bk.name") }}</div>
              <div class="font-weight-bold">
                {{ list[index].name }}
              </div>
            </v-col>
            <v-col cols="5">
              <div>{{ isStudent ? $t("app.nis") : $t("app.nip") }}</div>
              <div class="font-weight-bold">
                {{ isStudent ? list[index].nis : list[index].nip }}
              </div>
            </v-col>
            <v-col cols="7">
              <div>{{ $t("personalization.old_uid") }}</div>
              <div class="font-weight-bold">
                {{ list[index].uid || "-" }}
              </div>
            </v-col>
            <v-col cols="5">
              <div>
                <v-text-field
                  ref="formUID"
                  v-model="modelInput"
                  :disabled="loadingBtn"
                  :label="$t('personalization.input_card_number')"
                  :rules="inputRules"
                  @keyup.enter="post"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              :disabled="loadingBtn"
              outlined
              small
              color="primary"
              class="mr-2"
              @click="closeDialog"
              >{{ $t("app.cancel") }}</v-btn
            >
            <v-btn
              :loading="loadingBtn"
              :disabled="!modelInput"
              class="gradient-primary"
              dark
              depressed
              small
              @click="post"
              >{{ $t("app.save") }}</v-btn
            >
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirm
      :model="modelConfirm"
      :dataUID="dataUID"
      :loading="loadingDialog"
      @close="closeConfirm"
      @save="saveUID"
    />
  </v-row>
</template>

<script>
import { updateUID, getInfoUID } from "@/api/admin/absent/personalization";
import i18n from "@/i18n";

export default {
  props: {
    model: {
      type: Boolean,
      required: true
    },
    isStudent: {
      type: Boolean,
      required: true
    },
    listData: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    }
  },
  components: {
    DialogConfirm: () => import("./DialogConfirm")
  },
  data() {
    return {
      loadingDialog: false,
      dataUID: {
        name: "",
        uid: "",
        user_type: "",
        person: "",
        personName: "",
        personType: ""
      },
      currentPerson: "",
      currentType: "",
      currentName: "",
      modelConfirm: false,
      modelInput: null,
      loadingBtn: false,
      index: 0,
      list: [{ name: "", nis: "", uid: "" }],
      inputValid: true
    };
  },
  computed: {
    inputRules() {
      return [
        () =>
          !!this.inputValid || i18n.t("personalization.uid_already_registered")
      ];
    }
  },
  watch: {
    // focus textfield when open dialog
    model(v) {
      if (v) {
        this.list = this.listData;
        this.index = this.currentIndex;
        this.setFocusUID();
      }
    }
  },
  methods: {
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    closeConfirm(isSave) {
      if (isSave) this.closeDialog();
      else this.modelConfirm = false;
    },
    setFocusUID() {
      setTimeout(() => {
        this.$refs.formUID.focus();
      }, 200);
    },
    closeDialog() {
      this.index = 0;
      this.list = [{ name: "", nis: "", uid: "" }];
      this.inputValid = true;
      this.reset();
      this.getData();
      this.$emit("isOpen");
    },
    reset() {
      this.modelConfirm = false;
      this.$refs.formUID.reset();
      this.$refs.formUID.resetValidation();
    },
    getData(d) {
      this.$emit("getData", d);
    },
    async saveUID(dataUID) {
      this.loadingBtn = true;
      this.loadingDialog = true;
      try {
        const response = await updateUID(dataUID);
        if (response.data.code) {
          this.index++;
          this.snackBar(true, response.data.message);
          if (dataUID.uid_exist) {
            // close all dialog when uid exist
            this.closeDialog();
            this.modelConfirm = false;
          }
          this.reset();
          this.inputValid = true;
          this.setFocusUID();
          if (this.list.length === this.index) {
            this.closeDialog();
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.log("saveUID()", error);
      }
      this.loadingBtn = false;
      this.loadingDialog = false;
    },
    async showDialog(data) {
      this.dataUID = {
        person: this.currentPerson,
        ...data
      };
      this.modelConfirm = true;
    },
    post() {
      this.loadingBtn = true;
      return new Promise(resolve => {
        getInfoUID({
          uid: this.modelInput
        }).then(res => {
          this.loadingBtn = false;
          this.currentPerson = `${this.list[this.index].person}`;
          this.currentName = `${this.list[this.index].name}`;
          this.currentType = `${this.list[this.index].type}`;
          if (res.data.code) {
            this.dataUID = {
              ...res.data.data
            };
            this.dataUID.person = this.currentPerson;
            this.dataUID.personName = this.currentName;
            this.dataUID.personType = this.currentType;
            this.modelConfirm = true;
          } else {
            const body = {
              uid: this.modelInput,
              person: this.currentPerson,
              uid_exist: false
            };
            this.saveUID(body);
          }
          resolve(res);
        });
      });
    }
  }
};
</script>
